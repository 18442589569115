:root {
  --color-primary: #1e4c62;
  --color-primary-dark: #133342;
  --color-primary-light: #2d779a;
  --color-primary-lighter: #4b9dc3;
 
  --color-secondary: #b94e45;
  --color-secondary-light: #ea5e56;

  --color-background: #f5f5f5;
  --color-background-light: #efefef;
  --color-text-base: #565555;  
    
  --color-white: #ffffff;
  --color-black: #000;
  --color-yellow: #ffc107;
  --color-grey: #b7aeae;
  --color-grey-light: #ececec;
  --color-green: #128c7e;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background: var(--color-background);
}

body,
input,
button,
textarea {
    font-size: 1rem;
    font-weight: 500;
    font-family: Poppins !important;
    color: var(--color-text-base);
}

ul {
   list-style: none;
   margin: 0;
   padding: 0;
   
}

a {
    text-decoration: none !important;
}

.container {
    padding: 0 15px;
}

/* REACT LOADING MASK */
.rc-loading::after {
    background-color: var(--color-grey-lighter) !important;
    border-radius: 10px;
}

/* SCROLLBAR */
::-webkit-scrollbar-track {
    background-color: #F4F4F4;
}
::-webkit-scrollbar {
    width: 10px;
    background: #F4F4F4;
}
::-webkit-scrollbar-thumb {
    background: #dad7d7;
}

/* PLACEHOLDER */
::-webkit-input-placeholder { /* Edge */
    color: var(--color-text-base) !important;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--color-text-base) !important;
  }
  
  ::placeholder {
    color: var(--color-grey) !important;
  }

/* BOOTSTRAP */
.breadcrumb {
    background-color: inherit;
    padding: .75rem 0;
    margin: 0;
}

.breadcrumb .breadcrumb-item a {
    color: #7b7676;
    font-size: .85rem;
    font-weight: 600;
}

.breadcrumb-item{
    font-size: .9rem;
}

/* MATERIAL UI */
.MuiSlider-colorPrimary,
.MuiCheckbox-colorPrimary.Mui-checked,
.MuiSwitch-colorPrimary.Mui-checked {
    color: var(--color-primary-light) !important;
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: var(--color-primary-light) !important;
}

.MuiFormControlLabel-root {
    width: 100%;
}

/* SELECT 2 */
.select2-container--default .select2-selection--single {
    height: 40px;    
    padding: 6px;
    border: 1px solid var(--color-grey-light);
    outline: none;    
}

.select2-container {
    width: 100% !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 6px;
}

.select2-dropdown {
    border: 1px solid var(--color-grey-light);
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid var(--color-primary);
    outline: none;
}

.select2-container--default .select2-results__group {
    font-size: 12px;
    color: var(--color-secondary);
    padding: 5px 15px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: var(--color-primary-lighter);
}

.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: var(--color-grey-light);
    color: var(--color-text-base)
}

/* MEDIAS QUERIES */
@media (min-width: 1200px) {
    .container {
        max-width: 1240px;
    }
}