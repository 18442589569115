#container404 {
    width: 100vw;
    height: 100vh;
    padding: 15px;

    display: flex;
    justify-content: center;
    align-items: center;
}

#container404 .content {
    text-align: center;
    background: var(--color-white);
    padding: 20px 50px;
    box-shadow: 0 0 7px rgba(0,0,0,.1);
    border-radius: 10px;
}

#container404 .content h2{
    font-size: 1.5rem;
    color: var( --color-secondary);
}

#container404 .content h1{
    font-size: 2rem;
    color: var(--color-primary);
}

#container404 .content p {
    font-size: 1rem;
}

#container404 .content a {
    font-size: 1rem;
    font-weight: 700;
    color: var(--color-secondary);
}

#container404 .content h4 {
    margin-top: 20px;
}

#container404 .content .btn-content a {
    color: var(--color-white);
}

@media(max-width: 767px) {
    #container404 .content {
        padding: 20px;
    }

    #container404 .content h1{
        font-size: 30px;
    }

    #container404 .content p {
        font-size: 14px;
    }
}